<style lang="scss" scoped>
</style>
<template>
  <div>
    <div id="div1"></div>
    <el-button type="primary" style="margin-top: 20px" @click="addPro"
      >添加商品</el-button
    >
  </div>
</template>

<script>
import {
  defineComponent,
  reactive,
  onMounted,
  getCurrentInstance,
  watch,
} from "vue";
import E from "wangeditor";
import i18next from "i18next";
import defineLanguage from "@/plugins/defineLanguage";
import editorConfig from "@/plugins/editorConfig";

export default defineComponent({
  emits: {
    sendContent(data) {
      return data;
    },
  },
  props: {
    text: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const state = reactive({});
    const proxy = getCurrentInstance().appContext.config.globalProperties;
    const { $http } = proxy;
    let editor = null;

    onMounted(() => {
      editor = new E("#div1");
      Object.assign(editor.config, editorConfig);
      // 选择语言
      editor.config.lang = "fanti";
      // 自定义语言
      editor.config.languages["fanti"] = defineLanguage;
      // 引入 i18next 插件
      editor.i18next = i18next;
      editor.create();
      editor.config.customUploadImg = async (resultFiles, insertImgFn) => {
        // resultFiles 是 input 中选中的文件列表
        // insertImgFn 是获取图片 url 后，插入到编辑器的方法
        const formdata = new FormData();
        formdata.append("file", resultFiles[0]);
        // 上传图片，返回结果，将图片插入到编辑器中
        const { data } = await $http.upload(formdata);
        insertImgFn(data.file_path);
      };
      editor.txt.html(props.text);
    });

    watch(
      () => props.text,
      (val) => {
        editor.txt.html(val);
      }
    );

    const addPro = () => {
      emit("sendContent", editor.txt.html());
    };

    const getText = () => {
      return editor.txt.html();
    };

    const reset = () => {
      editor.txt.html("");
    };

    return { state, addPro, reset, getText };
  },
});
</script>
