<style lang="scss" scoped>
.text {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
}
.el-checkbox {
  margin-bottom: 20px;
  box-sizing: border-box;
  padding: 5px 20px;
  line-height: 25px;
}
.el-input {
  width: 80px;
}
</style>
<template>
  <div class="spec">
    <div v-for="item in state.specData" :key="item.id">
      <p class="text">{{ item.attr_key_name }}</p>
      <p
        class="text"
        style="margin-left: 20px"
        v-if="!item.goods_attr_combine.length"
      >
        該規格暫無屬性
      </p>
      <el-checkbox
        v-model="curr.checked"
        :label="curr.attr_values_name"
        border
        style="margin-left: 10px"
        v-for="curr in item.goods_attr_combine"
        :key="curr.id"
      >
        {{ curr.attr_values_name }}
        {{ curr.checked ? "價格:" : "" }}
        <el-input
          type="number"
          v-model="curr.pic"
          :max="999"
          size="mini"
          v-if="curr.checked"
          :maxlength="3"
        />
      </el-checkbox>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, getCurrentInstance, watch } from "vue";

export default defineComponent({
  props: {
    specData: {
      type: Object,
      default() {
        // 分类参数
        return [];
      },
    },
  },
  setup(props) {
    const proxy = getCurrentInstance().appContext.config.globalProperties;
    const { $http } = proxy;

    const state = reactive({
      specData: props.specData.length
        ? props.specData[0].goods_attr_keys
        : props.specData, // 分类参数
    });
    watch(
      () => props.specData,
      (val, old) => {
        val[0].goods_attr_keys.forEach((item) => {
          item.goods_attr_combine.forEach((curr) => {
            curr.checked = curr.checked ? curr.checked : false;
            curr.pic = curr.pic ? curr.pic : 0;
          });
        });
        state.specData = val[0].goods_attr_keys;
      }
    );

    return { state,props };
  },
});
</script>
